import React from 'react'

import { Seo } from '@/components/Seo'
import { Layout } from '@/components/layouts/Layout'
import styled from 'styled-components'
import { theme } from '@/styles/theme'
import { MainWrapper } from '@/styles/common'
import { navigate } from 'gatsby'

const CookieManagement = () => {
  const declineCookie = () => {
    document.cookie = `cookieAccept=false; max-age=31536000; path=/;`
    navigate('/')
  }
  return (
    <Layout>
      <Seo
        title="Cookie管理"
        canonical="https://su-pay.jp/cookie-management/"
        description="SU-PAYウェブサイトのCookieの管理を行います。"
      />
      <MainWrapper>
        <Wrapper>
          <DeclineCookieText onClick={declineCookie}>
            Cookieのご利用を停止する
          </DeclineCookieText>
        </Wrapper>
      </MainWrapper>
    </Layout>
  )
}

export default CookieManagement

const Wrapper = styled.div`
  margin: 80px 0 370px;
  &:hover {
    opacity: 0.6;
  }
`

const DeclineCookieText = styled.p`
  display: inline-block;
  color: ${theme.colors.supayBlue};
  text-decoration: underline;
  cursor: pointer;
`
